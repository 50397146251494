import React from 'react';
import styled from 'styled-components';

import { vendorLogosListReauth } from '../../../helper/constant';

const StyledSection = styled.section`
    max-width: 1280px;
    margin: 50px auto 0px auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const StyledHeading = styled.p`
    font-size: 24px;
    font-weight: 500;
    line-height: 19px;
    color: #000;
    text-align: center;
    margin-bottom: 0px;

    @media screen and (max-width: 600px) {
        font-size: 16px;
    }
`;

const LogoContainer = styled.div`
    max-width: 1200px;
    margin-top: 40px;
    display: flex;
    align-items: center;
    color: white;
    align-items: center;
    justify-content: center;
    gap: 80px;
    flex-wrap: wrap;

    @media screen and (max-width: 800px) {
        gap: 50px;
    }

    @media screen and (max-width: 600px) {
        display: none;
    }
`;

const Link = styled.a`
    text-decoration: none;
    cursor: pointer;
    transform: scale(1.4);

    @media screen and (max-width: 600px) {
        transform: scale(0.95);
    }
`;

const CompanyLogo = styled.img`
    opacity: 0.7;
`;

const LogoContainerResponsive = styled.div`
    max-width: 400px;
    margin-top: 24px;
    display: flex;
    align-items: center;
    color: white;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 12px;

    @media screen and (min-width: 601px) {
        display: none;
    }
`;

const LogoSection = () => {
    return (
        <StyledSection>
            <StyledHeading>
                Authenticate<sup>®</sup> is Trusted by
            </StyledHeading>
            <LogoContainer>
                {vendorLogosListReauth?.slice(0, 6).map((logo) => (
                    <Link href={logo.link} target="_blank" rel="noreferrer" key={logo.id}>
                        <CompanyLogo src={logo.logo} alt={logo.name} loading="lazy" />
                    </Link>
                ))}
            </LogoContainer>
            <LogoContainer>
                {vendorLogosListReauth?.slice(6).map((logo) => (
                    <Link href={logo.link} target="_blank" rel="noreferrer" key={logo.id}>
                        <CompanyLogo src={logo.logo} alt={logo.name} loading="lazy" />
                    </Link>
                ))}
            </LogoContainer>
            <LogoContainerResponsive>
                {vendorLogosListReauth.map((logo) => (
                    <Link href={logo.link} target="_blank" rel="noreferrer" key={logo.id}>
                        <CompanyLogo src={logo.logo} alt={logo.name} loading="lazy" />
                    </Link>
                ))}
            </LogoContainerResponsive>
        </StyledSection>
    );
};

export default LogoSection;
