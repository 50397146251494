import React from 'react';
// components
import ProductSeo from '../../components/ProductSeo';
import HeroSection from '../../sections/Product/Reauthenticate/heroSection';
import ReAuthenticateDemo from '../../images/product-assets/reauthenticateDemo.gif';
import ProductInfoSection from '../../sections/Product/Reauthenticate/productInfoSection';
import LogoSection from '../../sections/Product/Reauthenticate/logoSection';
import BenefitSection from '../../sections/Product/Reauthenticate/benefits/benefitSection';
import UseCaseSection from '../../sections/Product/Reauthenticate/useCases/useCaseSection';
import ProductFaq from '../../sections/Product/components/common/ProductFaq';
import { reauthenticateFaq } from '../../helper/constant';
// layout
import RootLayout from '../../layouts/RootLayout';

const ReauthenticatePage = () => {
    return (
        <>
            <ProductSeo
                title="ReAuthenticate™: Effortless Identity Verification with Just a Selfie"
                description="Streamline your onboarding and Know Your Customer (KYC) compliance with ReAuthenticate™, a simple SaaS that turns electronic KYC into a fast, seamless user experience."
                ogImage="https://cdn.authenticating.com/public/website/product_pages/reauthenticate_og_image.png"
            />
            <RootLayout isReauthenticate>
                <HeroSection
                    tag="Products · ReAuthenticate™"
                    subHeading="Streamline onboarding and KYC with ReAuthenticate™, a simple SaaS solution that turns an ordinarily painful process, into a seamless user experience."
                    heroImage={ReAuthenticateDemo}
                />
                <LogoSection />
                <ProductInfoSection />
                <BenefitSection />
                <UseCaseSection />
                <ProductFaq
                    faqData={reauthenticateFaq}
                    subHeading="Everything you need to know about the ReAuthenticate™."
                    titleColor="#262626"
                    reauthenticate={true}
                />
            </RootLayout>
            {/* <GlobalStyle />
            <Navbar isReauthenticate />

            <Footer /> */}
        </>
    );
};

export default ReauthenticatePage;
